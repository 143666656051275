// ./angular-client/src/app/todo/todo.service.ts
import { Injectable, Inject } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AppSettings } from '../../app.config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class Service {

  private apiUrl = AppSettings.API_ENDPOINT;
  private vapiUrl = AppSettings.VEH_ENDPOINT;

  constructor(private http: Http) { }

  verifyRider(inputs): Promise<any> {
    return this.http.post(this.vapiUrl + 'verifyNumber', inputs)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  getCountries(): Promise<any> {
    return this.http.get(this.apiUrl + 'countries')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  GetState(data): Promise<any> {
    return this.http.get(this.apiUrl + 'state/' + data)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  GetCity(data): Promise<any> {
    return this.http.get(this.apiUrl + 'city/' + data)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  getCompanies(): Promise<any> {
    return this.http.get(this.apiUrl + 'companies/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  getLangs(): Promise<any> {
    return this.http.get(this.apiUrl + 'languages/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  getCurrency(): Promise<any> {
    return this.http.get(this.apiUrl + 'currency/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  getCarMake(): Promise<any> {
    return this.http.get(this.apiUrl + 'carmake/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  getYearsData(): Promise<any> {
    return this.http.get(this.apiUrl + 'years/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  createDoc(inputs: any): Promise<any> {
    return this.http.post(this.apiUrl + 'riders/', inputs)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  updateRiderData(inputs: any): Promise<any> {
    return this.http.put(this.apiUrl + 'rider/', inputs)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  deleteRiderData(id: any): Promise<any> {
    return this.http.delete(this.apiUrl + 'rider/' + id)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  resetingPWD(id): Promise<any> {
    return this.http.post(this.apiUrl + 'riderResetPasswordFromAdmin/', id)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  private handleData(res: any) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    const body = error.json();
    return Promise.reject(body || error);
  }

}

@Injectable()
export class CommonService {
  constructor(@Inject(CommonService) public CommonService: CommonService) { }
}