/**
 * @license
 * Copyright Abservetech. All Rights Reserved.
 */
export class AppSettings {
  public static API = "https://salem.besttrackcalltaxi.in:3001/";
  public static API_ENDPOINT = AppSettings.API + "adminapi/";
  public static VEH_ENDPOINT = AppSettings.API + "api/";
  public static WEB_ENDPOINT = AppSettings.API + "webapi/";
  public static DRI_ENDPOINT = AppSettings.API + "api/";
  public static FILEURL = AppSettings.API + "public/";
  public static BASEURL = AppSettings.API;
  public static FEURL = AppSettings.API + "landing/";
  public static APPNAME = "Best Track";
  public static COPYRIGHTURL_FOOTER = "Best Track";
  public static LOGO_WIDTH = "400";
  public static LOGO_HEIGHT = "100";
  public static LOGO_URL = "assets/images/logo.png";
  public static DEFAULT_LOCATION = "Salem,India";
  public static ZOOM_MAP_LOCATION = 13;
  public static toShowSeocndCurrency = false;
  public static secondCurrency = "INR";
  public static defaultcur = "₹";
  public static defaultTime = "Mins";
  public static defaultUnit = "KM";
  public static defaultPhoneCode = "+91";
  public static showServiceCity = false;
  public static showWhatWeOffers = true;
  public static debounceTime = 500;
}

export class VerificationSettings {
  public static isVerify = true;
  public static verifyMethod = "phone"; // ['phone','email']
  public static methodName = "Phone Number"; // ['Email Address','Phone Number']
}
// abservetech
