export class LocationData {
  constructor(
    public _id: string,
    public location: string,
    public lng: number,
    public lat: number,
    public createdAt: Date,
    public from: string
  ) {
  }
}

export interface ILocation {
  name: string;
  locations: Location[];
}
