import { Component, OnInit } from '@angular/core';
import { ProfileService } from './profile.service';
import { AppSettings } from '../../app.config';
import { CommonService } from '../common/common.service';
import { ButtonToasterService } from '../buttontoaster/buttontoaster.service';
import { DatePipe } from '@angular/common';
import { Service } from '../ridersignup/ridersignup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataSharingService } from '../common/data-sharing.service';
import { Router } from '@angular/router';

@Component({
  selector: "profile",
  templateUrl: './profile.html',
  styleUrls: ['./profile.css'],
  providers: [ProfileService, CommonService, ButtonToasterService, DatePipe, Service]
})

export class ProfileComponent implements OnInit {
  documentMessage: string;
  selectedDocs: any;
  singleTaxi: any;
  selectedid: any;
  baseurl: string = AppSettings.BASEURL;
  private apiUrl = AppSettings.FEURL;
  expandedprofile = true;
  expandededitpassword = false;
  expandededitmobile = false;
  expandedprofiledetail = false;
  expandedemaildetail = false;
  expandedemail = true;
  list: any = {};
  SetDetail: any = {};
  SelectedUser: any;
  SelectedUserPic: any;
  driverLicencePic: any;
  taxiPassingPic: any;
  insuranceDocPic: any;
  initial: boolean;
  pic: string;
  companyary: any[] = [];
  countryary: any[] = [];
  stateary: any[] = [];
  cityary: any[] = [];
  langary: any[] = [];
  currencyary: any[] = [];
  updatePic: any;
  localUrl: any[];
  filedata: any;
  filedataProfile: any = [];
  filedataPassing: any = [];
  filedataInsurance: any = [];
  filedataLicence: any = [];
  filedataPassingN: any;
  filedataInsuranceN: any;
  filedataLicenceN: any;
  expiryDate: any;
  profileData: any;
  showProfileSave = false;

  constructor(private eservice: ProfileService,
    private dataService: Service,
    private datePipe: DatePipe,
    private bt: ButtonToasterService,
    private spinner: NgxSpinnerService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private tost: ButtonToasterService,
    private CommonSvc: CommonService) {
    this.list = localStorage.getItem('Id');
    this.getDriver();
  }

  getDriver() {
    this.spinner.show();
    this.eservice.getDetails()
      .then(res => {
        this.SetDetail = res[0];
        this.SelectedUser = this.SetDetail.fname;
        this.selectedid = this.SetDetail._id,
          this.SelectedUserPic = this.baseurl + res[0].profile;
        this.SetDetail.updatingUserPic = ''; // SetDetail.cntySetDetail.state
        this.spinner.hide();
        this.dataService.GetState(this.SetDetail.cnty)
          .then(msg => {
            this.stateary = msg[0]['states'];
          });
        this.dataService.GetCity(this.SetDetail.state)
          .then(msg => {
            this.cityary = msg[0]['cities'];
          });
      }).catch(res => {
        this.tost.showtoast('error', res.message);
        this.spinner.hide();
      });
  }

  ngOnInit(): void {
    this.CommonSvc
      .getCountries()
      .then(response => {
        this.countryary = response[0]['countries'];
      })
      .catch(res => {
        this.tost.showtoast('error', res.message);
      });
    this.dataService.getLangs()
      .then(msg => this.langary = msg[0]['datas'])
      .catch(res => {
        this.tost.showtoast('error', res.message);
      });
    this.dataService.getCurrency()
      .then(msg => this.currencyary = msg[0]['datas'])
      .catch(res => {
        this.tost.showtoast('error', res.message);
      });
  }

  GetState(data: any): void {
    if (!data) { return; }
    this.spinner.show();
    const selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.SetDetail.cntyname = selectElementText;

    const selectElementId = event.target['options']
    [event.target['options'].selectedIndex].value;

    this.dataService.GetState(selectElementId)
      .then(msg => {
        this.stateary = msg[0]['states'];
        this.spinner.hide();
      })
      .catch(res => {
        this.tost.showtoast('error', res.message);
        this.spinner.hide();
      });
  }

  GetCity(data: any): void {
    if (!data) { return; }
    this.spinner.show();
    const selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.SetDetail.statename = selectElementText;

    const selectElementId = event.target['options']
    [event.target['options'].selectedIndex].value;

    this.dataService.GetCity(selectElementId)
      .then(msg => {
        this.cityary = msg[0]['cities'];
        this.spinner.hide();
      })
      .catch(res => {
        this.tost.showtoast('error', res.message);
        this.spinner.hide();
      });
  }

  SetCity(data: any): void {
    if (!data) { return; }
    const selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.SetDetail.cityname = selectElementText;
  }

  uplaodDriverProfile(input) {

  }

  updatePassWord(input) {
    // input.emai=this.SetDetail.email;
    // this.eservice.resetPassWord(input)
    // .then(res=>{

    // })
  }

  editpassword(event) {
    // this.tost.showtoast("success", "I am working");
    this.expandededitpassword = !this.expandededitpassword;
    this.expandededitmobile = false;
    this.expandedemaildetail = false;
    // this.expandededitpassword = false;
  }

  editmobile(event) {
    // this.tost.showtoast("success", "I am working");
    this.expandededitmobile = !this.expandededitmobile;
    this.expandededitpassword = false;
    this.expandedemaildetail = false;
  }

  profiledetail(event) {
    this.expandedprofiledetail = !this.expandedprofiledetail;
    this.expandedprofile = !this.expandedprofile;
    this.showProfileSave = !this.showProfileSave;
    window.scroll(0, 0);
    this.getDriver();
  }

  logout() {
    localStorage.clear();
    this.dataSharingService.isUserLoggedIn.next(false);
    this.router.navigate(['/']);
  }

  editemail(event) {
    this.expandedemaildetail = !this.expandedemaildetail;
    this.expandededitpassword = false;
    this.expandededitmobile = false;
    this.expandedemail = !this.expandedemail;
  }

  submitDriverDocs(filefor, id) {
    //  console.log(filefor)
    this.spinner.show();
    if (this.filedata === undefined || this.expiryDate === undefined) {
      this.documentMessage = 'Please choose both document and Date';
    } else {
      const formdata = new FormData();
      formdata.append('file', this.filedata);
      formdata.append('licenceexp', this.expiryDate);
      formdata.append('driverid', this.selectedid);
      formdata.append('filefor', filefor);
      this.eservice.uploadDriverDocs(formdata)
        .then(msg => {
          this.changelook(msg, filefor);
          this.tost.showtoast('success', msg.message);
          const element = document.getElementById(id) as any;
          element.click();
          this.documentMessage = msg.message;
          this.spinner.hide();
        })
        .catch(res => {
          this.tost.showtoast('error', res.message);
          this.spinner.hide();
        });
    }
  }

  submitDriverDocsWithoutExpiry(filefor, id) {
    //  console.log(filefor)
    if (this.filedata === undefined) {
      this.documentMessage = 'Please Upload document';
      this.tost.showtoast('warn', 'Please Upload document');
    } else {
      this.spinner.show();
      const formdata = new FormData();
      formdata.append('file', this.filedata);
      // formdata.append('licenceexp', this.expiryDate);
      formdata.append('driverid', this.selectedid);
      formdata.append('filefor', filefor);
      this.eservice.uploadDriverDocs(formdata)
        .then(msg => {
          this.changelook(msg, filefor);
          this.tost.showtoast('success', msg.message);
          const element = document.getElementById(id) as any;
          element.click();
          this.documentMessage = msg.message;
          this.spinner.hide();
        })
        .catch(res => {
          this.tost.showtoast('error', res.message);
          this.spinner.hide();
        });
    }
  }

  getFilePath(e) {
    console.log(e.target.files[0]);
    this.profileData = e.target.files[0];
  }

  changelook(msg, filefor) {
    //   console.log(msg, filefor);
    switch (filefor) {
      case 'licence':
        this.SetDetail.licence = msg.file.path;
        this.SetDetail.licenceexp = msg.request.licenceexp;
        break;
      case 'insurance':
        this.SetDetail.insurance = msg.file.path;
        this.SetDetail.insuranceexp = msg.request.licenceexp;
        break;
      case 'passing':
        this.SetDetail.passing = msg.file.path;
        this.SetDetail.passingexp = msg.request.licenceexp;
        break;
      case 'register':
        this.singleTaxi.registration = msg.file.path;
        this.singleTaxi.registrationexpdate = msg.registrationexpdate;
        break;
      case 'permit':
        this.singleTaxi.permit = msg.file.path;
        this.singleTaxi.passingexp = msg.permitexpdate;
        break;
      case 'Insurance':
        this.singleTaxi.insurance = msg.file.path;
        this.singleTaxi.passingexp = msg.insuranceexpdate;
        break;
    }
  }

  updateRecord(type, inputs: any): void {
    if (!inputs) { return; }
    this.spinner.show();
    this.eservice.updateDriverData(inputs)
      .then(res => {
        const err = JSON.parse(res._body);
        if (err.success === true) {
          this.tost.showtoast('success', err.message);
          if (type === 'profile') {
            this.profiledetail('e');
          } else if (type === 'phone') {
            this.expandededitmobile = !this.expandededitmobile;
          } else if (type === 'bank') {
            this.expandedemaildetail = !this.expandedemaildetail;
          }
        }
        this.spinner.hide();
      })
      .catch(res => {
        this.tost.showtoast('error', res.message);
        this.spinner.hide();
      });
  }

  submitDriverDoc(filefor) {
    this.spinner.show();
    const formdata = new FormData();
    formdata.append('file', this.filedataProfile);
    this.eservice.uploadDriverDocs(formdata)
      .then(msg => {
        this.tost.showtoast('success', msg.message);
        this.spinner.hide();
      })
      .catch(res => {
        this.tost.showtoast('error', res.message);
        this.spinner.hide();
      });
  }

  logDate(msg) {
    // console.log(msg);
    this.expiryDate = this.datePipe.transform(msg.target.value, 'dd-MM-yyyy');
  }

  fileEvent2(e) {
    console.log(e.target.files[0]);
    this.profileData = e.target.files[0];
  }

  fileEvent(e) {
    console.log(e.target.files[0]);
    this.filedata = e.target.files[0];
  }

  fileEventProfile(e) {
    // console.log(e.target.files[0]);
    this.filedataProfile = e.target.files[0];
  }

  setProfile() {
    if (this.profileData) {
      this.spinner.show();
      const formdata = new FormData();
      formdata.append('file', this.profileData);
      formdata.append('email', this.SetDetail.email);
      formdata.append('fname', this.SetDetail.fname);
      formdata.append('lname', this.SetDetail.lname);
      this.eservice.uploadDriverProfile(formdata)
        .then(msg => {
          this.tost.showtoast('success', msg.message);
          this.SelectedUserPic = this.baseurl + msg.request.profile;
          this.showProfileSave = !this.showProfileSave;
          const element = document.getElementById('CloseButton') as any;
          element.click();
          this.spinner.hide();
        })
        .catch(res => {
          this.tost.showtoast('error', res.message);
          this.spinner.hide();
        });
    }
  }
}
