import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AppSettings } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class VacantService {

  private apiUrl = AppSettings.DRI_ENDPOINT;
  private TapiUrl = AppSettings.VEH_ENDPOINT;

  constructor(private http: Http) {

  }

  getVacantInfo(id): Promise<any> {
    return this.http.get(this.apiUrl + 'driver/vacant?vacantId=' + id)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  updateVacantInfo(token, obj): Promise<any> {
    let headers = new Headers();
    headers.append('x-access-token', token);
    let opts = new RequestOptions();
    opts.headers = headers;
    return this.http.put(this.apiUrl + 'driver/vacant', obj, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  private handleData(res: any) {
    const body = res.json();
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    const body = error.json();
    return Promise.reject(body || error);
  }
}
