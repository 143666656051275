// ./angular-client/src/app/todo/todo.service.ts
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, } from '@angular/http';
import { AppSettings } from '../../app.config';
import { Observable, defer } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ILocation, LocationData } from "./location.class";
import { HttpClient } from "@angular/common/http";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class SectionService {
  private apiUrl = AppSettings.WEB_ENDPOINT;
  private apiTUrl = AppSettings.API_ENDPOINT;

  initial: boolean;
  TapiUrl: string = AppSettings.VEH_ENDPOINT;
  token: string = localStorage.getItem('Tok');

  constructor(private http: Http, private httpClient: HttpClient) {
    // this.initial=false;
  }

  getDriverDetailsForATrip(inputs: any) {
    const headers = new Headers();
    headers.append('x-access-token', localStorage.getItem('Tok'));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .put(this.TapiUrl + 'tripDriverDetails/', inputs, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  riderFeedback(inputs: any) {
    const headers = new Headers();
    headers.append('x-access-token', localStorage.getItem('Tok'));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .put(this.TapiUrl + 'riderFeedback/', inputs, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  getVehicles(tok): Promise<any> {
    // let headers = new Headers();
    // headers.append('x-access-token', tok);
    // let opts = new RequestOptions();
    // opts.headers = headers;
    return this.http
      .post(this.TapiUrl + 'serviceBasicFare/daily', tok)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  requestTaxi(inputs: any): Promise<any> {
    const headers = new Headers();
    headers.append('x-access-token', localStorage.getItem('Tok'));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .post(this.TapiUrl + 'requestTaxi/', inputs, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }


  listVehicles(): Promise<any> {
    return this.http
      .get(this.apiTUrl + 'whatweofferfrontend')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  conBook(inputs: any): Promise<any> {
    return this.http
      .post(this.apiTUrl + "estimationFare/", inputs)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  getOurDrivers(): Promise<any> {
    return this.http
      .get(this.apiTUrl + 'ourDrivers/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  gethomecontent(): Promise<any> {
    return this.http
      .get(this.apiTUrl + 'gethomecontent/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  getHistoryLocatons(
    filter: { name: string } = { name: "" }
  ): Observable<ILocation> {
    return this.httpClient
      .get(this.apiTUrl + "/userLocations?search=" + filter.name)
      .pipe(
        tap((response: any) => {
          response.name = filter.name;
          response.locations = response.map(
            (user) =>
              new LocationData(
                user._id,
                user.location,
                user.lng,
                user.lat,
                user.createdAt,
                "api"
              )
          );
          console.log(response, "response2");

          return response;
        }),
        catchError((error) => [])
      );
  }

  private handleData(res: any) {
    const body = res.json();
    return body || {};
  }
  // createDoc(inputs:any): Promise<any>{
  //   let headers = new Headers();
  //   headers.append('x-access-token', myVariable);
  //   let opts = new RequestOptions();
  //   opts.headers = headers;

  //   return this.http.post(this.apiUrl + 'admin/', inputs, opts)
  //   .toPromise()
  //   .then(this.handleData)
  //   .catch(this.handleError)
  // }

  private handleError(error: any): Promise<any> {
    const body = error.json();
    return Promise.reject(body || error);
  }

  public getApiUrl() {
    return this.apiUrl + ' ';
  }
}
