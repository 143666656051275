import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  filter,
  debounceTime,
  tap,
  switchMap,
  finalize,
  catchError,
  map,
  skipLast,
} from "rxjs/operators";
import { AppSettings } from 'src/app/app.config';
import { LocationData } from '../section/location.class';
import { SectionService } from '../section/section.service';
import { VacantService } from './vacant.service';

@Component({
  selector: 'app-vacant',
  templateUrl: './vacant.component.html',
  styleUrls: ['./vacant.component.css'],
  providers: [VacantService, SectionService]
})
export class VacantComponent implements OnInit {
  list1: any;
  pickupformatAdd: any;
  id: any;
  response: any;
  status = false;

  pickupLocationform: FormGroup;
  customPickupSuggestions: LocationData[] = [];
  filteredData: any = {};
  debounceTime = AppSettings.debounceTime;
  isLoading_pickup = false;
  constructor(private router: ActivatedRoute, private service: VacantService, private ngxToastr: ToastrService, private fb: FormBuilder, private sectionService: SectionService,) {
    this.list1 = {
      pickupLocation: "",
    }
    this.router.params.subscribe(params => {
      console.log(params.id, "--------------->get id");
      this.id = params.id;
    });

  }

  getVacant() {
    this.service.getVacantInfo(this.id).then((res) => {
      console.log(res, "=================>");
      this.response = res;
      this.status = this.response.status;
    }).catch((err) => {
      console.log(err);
    })
  }
  submit() {
    let token = this.response.data.token;
    let obj = {
      lat: this.list1.pickupLat,
      lon: this.list1.pickupLng
    }

    this.service.updateVacantInfo(token, obj).then((res) => {
      console.log(res);
      this.ngxToastr.success(res.message, "Success");
    }).catch((err) => {
      console.log(err);
      this.ngxToastr.error(err.message, "Failed");
    })
  }

  ngOnInit() {
    this.initializeGoogleCustomPickup();
    this.getVacant();
  }
  initializeGoogleCustomPickup() {
    this.pickupLocationform = this.fb.group({
      pickupLocation: null,
    });

    this.pickupLocationform
      .get("pickupLocation")
      .valueChanges.pipe(
        filter(
          (_) => this.pickupLocationform.get("pickupLocation").value.length > 4
        ),
        debounceTime(this.debounceTime),
        tap(() => (this.isLoading_pickup = true)),
        switchMap((value) =>
          this.sectionService
            .getHistoryLocatons({ name: this.capitalizeFirstLetter(value) })
            .pipe(finalize(() => (this.isLoading_pickup = false)))
        ),
        catchError((_) => [])
      )
      .subscribe((users) => {
        console.log(users, "users")
        this.customPickupSuggestions = users.locations;
        console.log(this.customPickupSuggestions, "this.customPickupSuggestions")

      });
  }

  pickupListenLocationChange(data, type) {
    console.log(data, type, "pickupListenLocationChange")
    this.list1.pickupLocation = data;
    console.log("Pickuplisten", data);
    var filteredData = this.customPickupSuggestions.filter((address) => {
      return address.location == data;
    });
    if (filteredData.length) {
      this.filteredData = filteredData[0];
      console.log(this.filteredData);
      this.list1.pickupLocation = this.filteredData.location;
      this.pickupformatAdd = this.list1.pickupLocation;
      this.list1.pickupLat = this.filteredData.lat;
      this.list1.pickupLng = this.filteredData.lng;
    } else this.ngxToastr.error("Cannot Get Location")
    console.log(this.list1);
  }
  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
  }

}
